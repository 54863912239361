<template>
    <div class="page-main">
        <div>
            <b-card-group v-if="topcards">
                <b-row class="m-0 p-0 w-100">
                    <b-col class="topcardscol bg-white d-flex flex-column justify-content-center p-0 rounded" v-for="item in topcards" :key="item.id">
                        <b-card body-class="p-0">
                            <!-- <div class="d-flex align-items-end justify-content-end border-bottom p-2">
                                <b-form-select class="rounded-pill border px-2" :style="`color: ${item.color}`">
                                    <b-form-select-option value="1">Dia</b-form-select-option>
                                    <b-form-select-option value="2">Semana</b-form-select-option>
                                    <b-form-select-option value="3">Mês</b-form-select-option>
                                </b-form-select>
                            </div> -->
                            <div class="p-3 d-flex justify-content-between align-items-center">
                                <div class="d-flex align-items-center">
                                    <div class="d-flex rounded-circle p-2 text-white me-2" :style="`background-color: ${item.color}`">
                                        <b-icon :class="`d-flex ${item.class}`" :icon="item.icon" font-scale="2.5"></b-icon>
                                    </div>
                                    <div>
                                        <div class="topcardsnumber">{{item.number || 0}}</div>
                                        <div class="d-flex">
                                            <div class="topcardstext">{{item.text}}</div>
                                            <div v-if="item.text == 'Comunicações'">
                                                <b-form-select v-model="filterAttendancesCount" class="border-0 border-bottom text-secondary ms-2" @change="getAttendancesCount">
                                                    <b-form-select-option value="1">Dia</b-form-select-option>
                                                    <b-form-select-option value="2">Semana</b-form-select-option>
                                                    <b-form-select-option value="3">Mês</b-form-select-option>
                                                </b-form-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <b-icon class="text-shadow" icon="reception4" :style="`color: ${item.color}`" font-scale="4"></b-icon>
                                    <!-- <b-img style="width: 10em" :src="item.img"></b-img> -->
                                </div>
                            </div>
                        </b-card>
                    </b-col>
                </b-row>
            </b-card-group>
            <div v-else>
                <div class="d-flex justify-content-center mb-3">
                    <b-spinner label=""></b-spinner>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <b-row class="m-0 p-0">
                <b-col class="p-0 pe-2 d-flex flex-column" cols="4">
                    <div class="bg-purple h-100 mb-2 rounded p-3 d-flex align-items-center justify-content-center">
                        <b-icon class="text-white" font-scale="5" icon="file-person"></b-icon>
                    </div>
                    <div class="bg-white rounded mt-2">
                        <div class="p-3 h-100 d-flex flex-column justify-content-center">
                            <h5 class="m-0 text-secondary mb-3">Operadores Ativos no Momento</h5>
                            <div class="border-left-purple px-2">
                                <div class="text-purple h4 m-0">
                                    {{ operators.online || 0 }}
                                </div>
                                <div class="text-secondary">
                                    De {{ operators.total || 0 }} Operadores
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col class="p-0 ps-2" cols="8">
                    <div class="h-100 rounded" style="background-color: hsl(87deg 50% 62%)">
                        <b-card class="communicationsindexcard" body-class="p-0 pt-3">
                            <div class="d-flex flex-column">
                                <div class="d-flex align-items-center justify-content-between px-3">
                                    <h5 class="text-white m-0">Índice de Comunicações</h5>
                                    <span>
                                        <div class="d-flex align-items-center">
                                            <!-- <b-icon class="text-white fw-bold me-2" icon="circle" font-scale="0.75"></b-icon> -->
                                            <b-form-select class="rounded-pill border-0 px-2 me-2 text-green" v-model="filterAttendancesIndex.date" @change="getCommunicationsIndex">
                                                <b-form-select-option value="1">Dia</b-form-select-option>
                                                <b-form-select-option value="2">Semana</b-form-select-option>
                                                <b-form-select-option value="3">Mês</b-form-select-option>
                                            </b-form-select>
                                            <span class="bg-white rounded-pill px-2 me-2 text-green">
                                                TME:
                                                <span class="fw-bold"> {{ formatTime(tme) }}</span>
                                            </span>
                                            <span class="bg-white rounded-pill px-2 text-green">
                                                TMA:
                                                <span class="fw-bold"> {{ formatTime(tma) }}</span>
                                            </span>
                                        </div>
                                    </span>
                                </div> 
                                <div class="mt-2">
                                    <Chart :data="communicationsIndexChart || []" :height="200" v-if="communicationsIndexChart" />
                                </div>
                            </div>
                        </b-card>
                        <b-card class="chartbottom rounded-bottom p-0 m-0" body-class="p-0">
                            <div class="chartTabWrapper position-absolute d-flex align-items-center w-100">
                                <span class="mb-4" v-if="showChartTabArrows">
                                    <b-icon icon="chevron-left"
                                    class="chartArrow rounded-circle bg-success ms-1" variant="light"
                                    font-scale="1.5" scale="0.75" @click="moveLeft" role="button"></b-icon>
                                    <b-icon icon="chevron-right"
                                    class="chartArrow rounded-circle bg-success me-1" variant="light" 
                                    font-scale="1.5" scale="0.75" @click="moveRight" role="button"></b-icon>
                                </span>
                                <div class="chartPadding w-100">
                                    <div class="chartTab rounded-bottom d-flex">
                                        <div class="chartCard shadow-sm rounded p-3 mb-1" v-for="item in chartcards" :key="item.id">
                                            <b-icon class="charticon" :icon="item.icon"></b-icon>
                                            <b-form-radio v-model="filterAttendancesIndex.status" class="chartcheck" name="radio" :value="item.value" @change="getCommunicationsIndex"></b-form-radio>
                                            <span class="charttitle">{{ item.title }}</span>
                                            <span class="chartdesc" v-if="filterAttendancesIndex.status == item.value">{{ communicationsIndexStatusCount }} {{ item.description }}</span>
                                        </div> 
                                    </div>
                                </div> 
                            </div>
                        </b-card>
                    </div>
                </b-col>
            </b-row>
        </div>
        <!-- <div class="mt-3">
            <b-row class="m-0 p-0">
                <b-col class="p-0">
                    <div class="d-flex flex-column bg-white h-100 rounded">
                        <div class="d-flex justify-content-between align-items-center border-bottom p-3">
                            <div class="text-secondary fw-semibold small-text">
                                Recados Recebidos
                            </div>
                            <div>
                                <b-form-select class="rounded-pill border px-2 text-secondary">
                                    <b-form-select-option value="1">Dia</b-form-select-option>
                                    <b-form-select-option value="2">Semana</b-form-select-option>
                                    <b-form-select-option value="3">Mês</b-form-select-option>
                                </b-form-select>
                            </div>
                        </div>
                        <div>
                            <b-row class="m-0 p-3">
                                <b-col class="p-0" v-for="item in receivedMessagesItems" :key="item.id">
                                    <div class="d-flex align-items-center">
                                        <span :class="`rounded-circle p-2 text-white me-2 bg-${item.class}`">
                                            <b-icon class="d-flex" :icon="item.icon" font-scale="1.2"></b-icon>
                                        </span>
                                        <span>
                                            <div class="fw-bold">{{item.title}}</div>
                                            <div class="smaller-text text-secondary">{{item.subtitle}}</div>
                                        </span>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                        <div class="position-relative w-100 h-100 d-flex">
                            <Chart class="w-100" :height="250" colorClass="pink" noBorder="true"/>
                            <Chart class="position-absolute bottom-0 w-100" :height="220" colorClass="purple" noBorder="true"/>
                            <Chart class="position-absolute bottom-0 w-100" :height="190" colorClass="yellow" noBorder="true"/>
                            <Chart class="position-absolute bottom-0 w-100" :height="160" colorClass="green" noBorder="true"/>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div> -->
        <div class="mt-3">
            <b-row class="m-0">
                <b-col class="p-0 bg-white rounded me-3 d-flex flex-column">
                    <div class="border-bottom p-3">
                        <div class="fs-5 fw-bold text-secondary">
                            Menus Mais Acessados
                        </div>
                    </div>
                    <div class="h-100" v-if="!channelConfig?.operationalChannelEnabled">
                        <div class="d-flex flex-column h-100" v-if="topMenus?.length">
                            <div class="topMenusWrapper p-3 py-4">
                                <div class="pb-3" v-for="item in topMenus.slice(0,5)" :key="item.id">
                                    <div class="px-2 py-1" :style="`border-left: 4px solid ${item.color}`">
                                        <div class="text-secondary fw-bold m-0">
                                            Menu {{ item.option }}
                                        </div>
                                        <div class="mb-1 small-text text-secondary">
                                            {{ item.quantity }} Acessos este mês
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-3 h-100 w-100 d-flex border-top" v-if="topMenus.length > 5">
                                <div class="d-flex w-100 justify-content-end align-self-end">
                                <b-button class="rounded-pill border" variant="secondary-outline" v-b-modal.allMenus>Ver Todos os Menus de Acesso</b-button>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-center h-100 text-secondary p-3" v-else>
                            Nenhum menu acessado!
                        </div>
                    </div>
                    <div class="d-flex align-items-center justify-content-center h-100 text-secondary p-3" v-else>
                        Plataforma configurada como Operacional!
                    </div>
                </b-col>
                <b-col class="p-0 bg-white rounded me-3 d-flex flex-column">
                    <div class="border-bottom p-3">
                        <div class="fs-5 fw-bold text-secondary">
                            Top Tabulações
                        </div>
                    </div>
                    <div class="h-100">
                        <div class="d-flex flex-column h-100" v-if="topTabulations?.length">
                            <div class="topMenusWrapper p-3 py-4">
                                <div class="pb-3" v-for="item in topTabulations.slice(0,5)" :key="item.id">
                                    <div class="px-2 py-1" :style="`border-left: 4px solid ${item.color}`">
                                        <div class="text-secondary fw-bold m-0">
                                            {{ item.tabulation }}
                                        </div>
                                        <div class="mb-1 small-text text-secondary">
                                            {{ item.count }} Utilizações este mês
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="p-3 h-100 w-100 d-flex border-top" v-if="topTabulations.length > 5">
                                <div class="d-flex w-100 justify-content-end align-self-end">
                                <b-button class="rounded-pill border" variant="secondary-outline" v-b-modal.allTabulations>Ver Todas as Tabulações</b-button>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-center h-100 text-secondary p-3" v-else>
                            Nenhuma tabulação utilizada!
                        </div>
                    </div>
                </b-col>
                <!-- <b-col class="p-0 bg-white rounded d-flex flex-column">
                    <div class="border-bottom p-3 d-flex justify-content-between align-items-center">
                        <div class="fs-5 fw-bold text-secondary">
                            Aniversariantes
                        </div>
                        <b-button class="rounded-pill px-3 py-1 text-white" variant="green" v-b-modal.modal-birthday v-if="birthdays.contacts && birthdays.contacts.length">
                            <b-icon class="d-flex" icon="gift"></b-icon>
                        </b-button>
                    </div>
                    <div class="d-flex flex-column h-100" v-if="birthdays.contacts && birthdays.contacts.length">
                        <div>
                            <b-table
                            id="calls-table"
                            class="tablecalls"
                            tbody-tr-class="border-bottom"
                            :items="birthdays.contacts"
                            :fields="['name']"
                            :sort-desc='false'
                            responsive="sm"
                            borderless
                            >
                                <template #cell(name)="data">
                                    <b-col cols="2" class="peopleiconcol d-flex flex-basis-content me-2">
                                        <span v-if="data.item.photoURL">
                                            <img class="peopleicon" :src="data.item.photoURL">
                                        </span>
                                        <span v-else>
                                            <img class="peopleicon" src="https://firebasestorage.googleapis.com/v0/b/notifiqueai-app.appspot.com/o/assets%2Fdefault.jpg?alt=media&token=73be2b6f-a8a3-4407-b079-ab3918d37d8c">
                                        </span>
                                    </b-col>
                                    <b-col class="d-flex align-items-center">
                                        <div class="lh-1">
                                            <div class="text-secondary fw-bold">{{data.item.name}}</div>
                                            <div class="text-secondary small-text" v-if="data.item.company">{{data.item.company}}...</div>
                                        </div>
                                    </b-col>
                                    <b-col cols="2" class="d-flex align-items-center justify-content-end">
                                        <b-button class="rounded-pill px-3 py-1" variant="outline-secondary" @click="selectItem(data.item)" v-b-modal.modal-birthday>
                                            <b-icon class="d-flex" icon="gift"></b-icon>
                                        </b-button>
                                    </b-col>
                                </template>
                            </b-table>
                        </div>
                        <div class="p-3 h-100 w-100 d-flex">
                            <div class="d-flex w-100 justify-content-end align-self-end">
                            <b-button class="rounded-pill border" variant="secondary-outline" v-b-modal.allBirthdays :disabled="birthdays.contacts.length>5">Ver Todos os Aniversariantes</b-button>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex h-100 align-items-center justify-content-center text-secondary p-3" v-else>
                        Nenhum contato faz aniversário hoje!
                    </div>
                </b-col> -->
            </b-row>
        </div>
        <b-modal id="modal-birthday" header-class="py-0 px-4">
            <div slot="modal-title">
                <b-icon class="modal-birthdayheadericon" icon="basket"></b-icon>
                Aniversariantes, Presente
            </div>
            <div class="modal-birthdaysubheader">
                <div>Personalize uma mensagem pré-definida a qual será</div> 
                <div>enviada para {{ itemSelected ? "o aniversariante" : "os aniversariantes" }}</div>
            </div>
            <div class="modal-birthdaymain">
                <!-- <div class="modal-birthdayitem">
                    <div class="modal-birthdaymaintitles my-0">Automatico?</div>
                    <span class="modal-birthdayradio d-flex">
                        <b-form-radio class="me-4 text-secondary" name="radio-birthdayMsg">Sim</b-form-radio>
                        <b-form-radio class="text-secondary" name="radio-birthdayMsg">Não</b-form-radio>
                    </span>
                </div> -->
                <div class="modal-birthdayitem">
                    <div class="modal-birthdaymaintitles">Selecione a Imagem:</div>
                    <b-form-file
                    class="modal-birthdayFileInput"
                    @input="setBirthdayImg"
                    >
                        <template #placeholder>
                            <div style="height:325px;" v-if="birthdayMsg.img">
                                <img class="insertfilediv" :src="birthdayMsg.img" style="width: 100%" >
                            </div>
                            <div class="modal-birthdayinsertimg" v-else>
                                <b-icon class="modal-birthdayinsertimgicon" icon="image"></b-icon>
                                <div class="modal-birthdayinsertimgtext">Jogue aqui sua imagem</div>
                                <div class="modal-birthdayinsertimgsubtext">ou <span class="modal-birthdayinsertimgclic">clique aqui</span> para anexar a imagem</div>
                            </div>
                        </template>
                        <template #file-name>
                            <div style="height:325px;">
                                <img class="insertfilediv" :src="birthdayMsg.img" style="width: 100%" >
                            </div>
                        </template>
                        <template #drop-placeholder>
                            <div class="modal-birthdayinsertimg">
                                <b-icon class="modal-birthdayinsertimgicon" icon="image"></b-icon>
                                <div class="modal-birthdayinsertimgtext">Solte a imagem aqui</div>
                            </div>
                        </template>
                    </b-form-file>
                </div>
                <div class="modal-birthdayitem">
                    <div class="modal-birthdaymaintitles">Mensagem para o (a) Aniversariante: </div>
                    <div class="position-relative">
                        <b-form-textarea
                            v-model="birthdayMsg.msg"
                            class="px-4"
                            id="textarea"
                            :placeholder="itemSelected ? 'Dê o parabéns ao Aniversariante...' : 'Dê o parabéns aos Aniversariantes...'"
                            rows="6"
                            no-resize
                        />
                        <b-button class="toggle-emoji" @click="toggleDialogEmoji"><b-icon class="toggle-emoji-icon" icon="emoji-smile"></b-icon></b-button>
                        <VEmojiPicker @select="selectEmoji" lang="pt-BR" v-show="showDialog" :i18n="i18n" />
                    </div>
                </div>
                <div class="modal-birthdayitem">
                    <b-button class="modal-birthdaybtn modal-birthdaybtnsend">Enviar</b-button>
                    <!-- <b-button class="modal-birthdaybtn">Salvar</b-button> -->
                    <b-button @click="$bvModal.hide('modal-birthday')" class="modal-birthdaybtn">Cancelar</b-button>
                </div>
            </div>
        </b-modal>
        <b-modal id="allBirthdays" ref="allBirthdays" header-class="py-0" body-class="p-0" hide-footer title="Todos os Aniversariantes">
            <b-table
            id="allBirthdays-table"
            class="tablecalls m-0"
            tbody-tr-class="border-bottom"
            :items="birthdays.contacts"
            :fields="['name']"
            :sort-desc='false'
            responsive="sm"
            borderless
            >
                <template #cell(name)="data">
                    <b-col cols="2" class="peopleiconcol d-flex flex-basis-content me-2">
                        <span v-if="data.item.photoURL">
                            <img class="peopleicon" :src="data.item.photoURL">
                        </span>
                        <span v-else>
                            <img class="peopleicon" src="https://firebasestorage.googleapis.com/v0/b/notifiqueai-app.appspot.com/o/assets%2Fdefault.jpg?alt=media&token=73be2b6f-a8a3-4407-b079-ab3918d37d8c">
                        </span>
                    </b-col>
                    <b-col class="d-flex align-items-center">
                        <div class="lh-1">
                            <div class="text-secondary fw-bold">{{data.item.name}}</div>
                            <div class="text-secondary small-text" v-if="data.item.company">{{data.item.company}}...</div>
                        </div>
                    </b-col>
                    <b-col cols="2" class="d-flex align-items-center justify-content-end">
                        <b-button class="rounded-pill px-3 py-1" variant="outline-secondary" @click="selectItem(data.item)" v-b-modal.modal-birthday>
                            <b-icon class="d-flex" icon="gift"></b-icon>
                        </b-button>
                    </b-col>
                </template>
            </b-table>
            <div class="d-flex justify-content-between align-items-center p-3" v-if="birthdays.count">
                <b-pagination class="p-0 m-0" v-model="birthdaysCurrentPage" :total-rows="birthdays.count" :per-page="birthdaysPerPage" @change="birthdaysPageChange"></b-pagination>
                <div class="text-secondary">{{ ( birthdaysCurrentPage > 1 ) ? ((birthdaysCurrentPage) * birthdaysPerPage) - birthdaysPerPage + 1 : birthdaysCurrentPage }} - {{(birthdaysCurrentPage * birthdaysPerPage > birthdays.count) ? birthdays.count : birthdaysCurrentPage * birthdaysPerPage}} de {{birthdays.count}} aniversariantes</div>
            </div>
        </b-modal>
        <b-modal id="allMenus" ref="allMenus" header-class="py-0" body-class="p-0" hide-footer title="Todos os Menus" v-if="topMenus">
            <div class="topMenusWrapper p-3 py-4 border-bottom">
                <div class="pb-3" v-for="item in topMenus" :key="item.id">
                    <div class="px-2 py-1" :style="`border-left: 4px solid ${item.color}`">
                        <div class="text-secondary fw-bold m-0">
                            Menu {{ item.option }}
                        </div>
                        <div class="mb-1 small-text text-secondary">
                            {{ item.quantity }} Acessos
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
        <b-modal id="allTabulations" ref="allTabulations" header-class="py-0" body-class="p-0" hide-footer title="Todas as Tabulações" v-if="topTabulations">
            <div class="topMenusWrapper p-3 py-4 border-bottom">
                <div class="pb-3" v-for="item in topTabulations" :key="item.id">
                    <div class="px-2 py-1" :style="`border-left: 4px solid ${item.color}`">
                        <div class="text-secondary fw-bold m-0">
                            {{ item.tabulation }}
                        </div>
                        <div class="mb-1 small-text text-secondary">
                            {{ item.count }} Utilizações este mês
                        </div>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { VEmojiPicker } from 'v-emoji-picker';
import Chart from './chart.vue'
import utils from '../utils/utils.js'
import api from '../services/apiService.js'
import userService from '../services/userService.js'

export default {
    props: [
        'user'
    ],
    components: {
        Chart,VEmojiPicker,
    },
    mounted: function () {
        this.onCloseModal()
        this.getScreenSize()
        this.getContactsCount()
        this.getChannelConfig()
        this.getOperatorsCount()
        this.getAttendancesCount()
        this.getCommunicationsIndex()
        this.getTopTabulations()
        this.getTopMenus()
        window.addEventListener('resize', this.chartTabControl());
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.chartTabControl()); 
    },
    methods: {
        selectItem(item) {
            this.itemSelected=item
        },
        onCloseModal() {
            this.$root.$on('bv::modal::hide',() => {
                this.itemSelected = null;
            })
        },
        toggleDialogEmoji() {
            this.showDialog = !this.showDialog;
        },
        selectEmoji(emoji) {
            this.birthdayMsg.msg += emoji.data
            this.toggleDialogEmoji()
        },
        length(element) {
            return(element.length)
        },
        setBirthdayImg(e) {
            const reader = new FileReader()
            reader.readAsDataURL(e)
            reader.onload = ()=>{
                const dataURL = reader.result
                this.birthdayMsg.img = dataURL.replace('base64','charset=utf-8;base64')
            }
        },
        moveRight() {
            document.querySelector('.chartTab').scrollLeft += 226;
        },
        moveLeft() {
            document.querySelector('.chartTab').scrollLeft -= 226;
        },
        getScreenSize() {
            // setInterval(()=>{
            //     this.width  =  document.documentElement.clientWidth || window.innerWidth ||
            //     document.body.clientWidth;
            //     this.height =  document.documentElement.clientHeight || window.innerHeight ||
            //     document.body.clientHeight;
            // },1000) 
        },
        chartTabControl() {
            const chartTab = document.querySelector('.chartTabWrapper')
            if(chartTab) {
                if((chartTab.offsetWidth<429 && this.chartcards.length>2) ||
                    (chartTab.offsetWidth<933 && this.chartcards.length>3) ||
                    (chartTab.offsetWidth<1025 && this.chartcards.length>4) ||
                    (chartTab.offsetWidth<1187 && this.chartcards.length>5)
                ) {
                    this.showChartTabArrows = true
                    return
                }
            }
            this.showChartTabArrows = false
        },
        // async getBirthdayContacts(page=1) {
        //     let resp = await api.getBirthdayContacts(this.user.channelId || this.user.roleId, page)
        //     console.log('resp birthday',resp)
        //     if(resp && resp.statusCode == 200) {
        //         this.birthdays = resp.birthdays
        //     }
        // },
        async getContactsCount() {
            const totalResp = await api.getContactsTotal(this.user.channelId || this.user.roleId)
            if(totalResp.statusCode!=200) {
                this.topcards[0].number = 0
            } else if(totalResp.statusCode == 401)
                userService.logout()
            this.topcards[0].number = totalResp.numberContacts
        },
        async getChannelConfig() {
            const resp = await api.getChannelConfig(this.user.channelId || this.user.roleId)
            // console.log('resp',resp)
            if(resp.statusCode == 200) {
                this.channelConfig = resp.channelConfig
            }
        },
        async getOperatorsCount() {
            const channelId = this.user.channelId || this.user.roleId
            const respOp = await api.getOperators(channelId)
            // console.log('resp op',respOp)
            if(respOp.statusCode == 200) {
                this.$set(this.operators,'total',respOp.operators.length)
            }
            const respOnlineOp = await api.getOnlineOperators(channelId)
            // console.log('resp OnlineOp',respOnlineOp)
            if(respOnlineOp.statusCode == 200) {
                this.$set(this.operators,'online',respOnlineOp.operators.length)
                // console.log(this.operators.online)
            }
        },
        async getAttendancesCount() {
            const resp = await api.getAttendancesCount(this.user.channelId || this.user.roleId,this.filterAttendancesCount)
            // console.log('resp attCount',resp)
            this.topcards[1].number = resp.attendances
        },
        async getCommunicationsIndex() {
            const channelId = this.user.channelId || this.user.roleId
            this.communicationsIndexChart = null
            const resp = await api.getCommunicationsIndex(channelId,this.filterAttendancesIndex)
            // console.log('resp communicationsIndex',resp)
            if(resp.statusCode == 200) {
                const chart = { labels: [], values: [] }
                this.communicationsIndexStatusCount = 0
                for(const index in resp.attendances) {
                    if(this.filterAttendancesIndex.status) {
                        this.communicationsIndexStatusCount += resp.attendances[index].count
                    }
                    switch(this.filterAttendancesIndex.date) {
                        case 1:
                        case '1':
                            chart.labels.push(resp.attendances[index].hour)
                            break
                        case 2:
                        case '2':
                        case 3:
                        case '3':
                            chart.labels.push(resp.attendances[index].date)
                            break
                    }
                    chart.values.push(resp.attendances[index].count)
                }
                this.communicationsIndexChart = chart
            }
            const respTME = await api.getTME(channelId,this.filterAttendancesIndex)
            // console.log('respTME',respTME)
            if(respTME.statusCode == 200) {
                // let time = new Date(respTME.averageTime)
                // this.tme = time.getHours().toString().padStart(2, '0') + ':' + time.getMinutes().toString().padStart(2, '0') + ':' + time.getSeconds().toString().padStart(2, '0')
                this.tme = respTME.averageTime
            } else this.tme = "00:00:00"
            const respTMA = await api.getTMA(this.user.channelId || this.user.roleId,this.filterAttendancesIndex)
            // console.log('respTMA',respTMA)
            if(respTMA.statusCode == 200) {
                // let time = new Date(respTMA.averageTime)
                // this.tma = time.getHours().toString().padStart(2, '0') + ':' + time.getMinutes().toString().padStart(2, '0') + ':' + time.getSeconds().toString().padStart(2, '0')
                this.tma = respTMA.averageTime
            } else this.tma = "00:00:00"
        },
        async getTopTabulations() {
            const resp = await api.getReportTabulationCount({ 
                channelId: this.user.channelId || this.user.roleId, 
                filter: { date: 3 }
            })
            // console.log('resp topt',resp)
            if(resp.statusCode == 200 && resp.attendances.length) {
                const tabulations = []
                const promise = resp.attendances.map(async el => {
                    if(el.completionReason?.length) {
                        const p = el.completionReason.map(tab => {
                            if(tab && tab != "Atendimento finalizado pelo tempo de espera." && tab != "finished") {
                                const found = tabulations.find(el => el.tabulation == tab)
                                if (!found) tabulations.push({ tabulation: tab, count: 1, color: utils.generateColor() })
                                else found.count++
                            }
                        })
                        await Promise.all(p)
                    }
                })
                await Promise.all(promise)
                this.topTabulations = tabulations.sort((a,b) => (a.count < b.count) ? 1 : ((b.count < a.count) ? -1 : 0))
                // console.log('topTab',this.topTabulations)
            }
        },
        async getTopMenus() {
            const resp = await api.getTopMenus(this.user.channelId || this.user.roleId)
            if(resp.statusCode == 200 && resp.menus.length) {
                resp.menus = resp.menus.filter(el => Object.hasOwn(el, 'option'))
                const promise = resp.menus.map(el => {
                    el.color = utils.generateColor()
                    return el
                })
                await Promise.all(promise)
                this.topMenus = resp.menus.sort((a,b) => (a.quantity < b.quantity) ? 1 : ((b.quantity < a.quantity) ? -1 : 0))
            }
        },
        birthdaysPageChange(e) {
            if(e != this.currentPage) {
                this.currentPage = e
                this.getBirthdayContacts(e)
            }
        },
        formatTime(ms) { // HH:MM:SS
            if(ms) {
                const pad = (n, z = 2) => ('00' + n).slice(-z);
                return pad(ms/3.6e6|0) + ':' + pad((ms%3.6e6)/6e4 | 0) + ':' + pad((ms%6e4)/1000|0);
            }
            return "00:00:00"
        },
    },
    data() {
        return{
            channelConfig: null,
            showChartTabArrows: false,
            itemSelected: null,
            birthdayMsg: {},
            filterAttendancesCount: 1,
            filterAttendancesIndex: { date: 1 },
            topcards: [
                {
                    icon: 'person-fill',
                    color: 'hsl(86deg 55% 65%)',
                    number: 0,
                    text: 'Contatos',
                    img: 'https://firebasestorage.googleapis.com/v0/b/gotalk-app.appspot.com/o/assets%2Fchart.png?alt=media&token=a7b82dad-9ee6-4a67-8e13-51a09f48d391',
                },
                {
                    icon: 'chat-fill',
                    color: 'hsl(286deg 62% 70%)',
                    number: 0,
                    text: 'Comunicações',
                    img: 'https://firebasestorage.googleapis.com/v0/b/gotalk-app.appspot.com/o/assets%2Fchart.png?alt=media&token=a7b82dad-9ee6-4a67-8e13-51a09f48d391',
                    class: 'pb-1',
                    date: 1
                },
                // {
                //     icon: 'heart',
                //     color: 'hsl(12deg 88% 59%)',
                //     number: 1000,
                //     text: 'Recados',
                //     img: 'https://firebasestorage.googleapis.com/v0/b/gotalk-app.appspot.com/o/assets%2Fchart2.png?alt=media&token=77cbed27-a859-4314-9627-2cfdd7477f65',
                //     class: 'pt-1',
                // },
            ],
            communicationsIndexChart: null,
            communicationsIndexStatusCount: 0,
            tme: '00:00:00',
            tma: '00:00:00',
            chartcards: [
                {
                    title: 'Em Fila',
                    description: 'Cliente(s)',
                    icon: 'person',
                    value: 'offline_operators'
                },
                {
                    title: 'Em atendimento',
                    description: 'Cliente(s)',
                    icon: 'person-square',
                    value: 'in_attendance'
                },
                {
                    title: 'Aguardando Atendimento',
                    description: 'Cliente(s)',
                    icon: 'clock',
                    value: 'waiting'
                },
                {
                    title: 'Finalizados',
                    description: 'Atendimento(s)',
                    icon: 'check2',
                    value: 'finished'
                },
            ],
            topMenus: [],
            topTabulations: [],
            showDialog: false,
            input: '',  
            i18n: {
                search: 'Pesquisar...',
                categories: {
                    Activity: "Atividades",
                    Flags: "Bandeiras",
                    Foods: "Comida",
                    Frequently: "Frequentes",
                    Objects: "Objetos",
                    Nature: "Natureza",
                    Peoples: "Pessoas",
                    Symbols: "Símbolos",
                    Places: "Locais"
                }
            },
            operators: {},
            birthdays: {},
            birthdaysCurrentPage: 1,
            birthdaysPerPage: 5,
        }
    }
}
</script>

<style>
    .tablecalls .table > thead{
        display: none;
    }
    .tablecalls td{
        padding: 1em;
        display: flex;
    }
    .modal-content{
        border: none;
    }
    #modal-birthday .close{
        border: none;
        background-color: transparent;
        font-size: 2em;
        color: #777;
    }
    #modal-birthday .modal-header{
        color: #777;
        border-bottom: 0.5px solid #eee;
    }
    #modal-birthday .modal-footer{
        display:none;
    }
    #modal-birthday .modal-body{
        padding: 0px;
    }
    .modal-birthdayradio .custom-control-input{
        margin-right: 5px;
    }
    select{
        border: 1px solid #eee;
    }
    .emoji-picker{
        position: absolute;
        width: 250px;
    }
    .emoji-picker #Categories {
        order: 5;
    }
    .emoji-picker #InputSearch {
        order: 4;
    }
    .emoji-picker .border{
        border: none !important;
    }
    .emoji-picker .emoji{
        display: flex !important;
        justify-content: center !important;
    }
    .emoji-picker .container-emoji{
        overflow: auto !important;
    }
    body *{
        scrollbar-color: hsl(244deg 26% 80%) hsl(228deg 20% 95%);
        scrollbar-width: thin;
    }
    body *::-webkit-scrollbar{
       width: 12px;
    }
    body *::-webkit-scrollbar-track{
        background: hsl(228deg 20% 95%);
    }
    body *::-webkit-scrollbar-thumb{
        background-color: hsl(244deg 26% 80%);
    }
    .modal-birthdayFileInput>input{
        display: none;
    }
    .modal-birthdayFileInput>label{
        width: 100%;
        cursor: pointer;
    }
</style>

<style scoped>
    .card{
        border: none;
    }
    .topcardscol:first-child{
        margin-right: .5rem; 
    }
    .topcardscol:last-child{
        margin-left: .5rem; 
    }
    .topcards{
        padding: 15px 25px;
    }
    .topcards .row{
        align-items: center;
    }
    .topcardsiconcol{
        width: fit-content;
        padding: 0;
    }
    .topcardsicon{
        color: #fff;
        font-size: 4.5em;
        border-radius: 50%;
        padding: 10px;
    }
    .topcardsinfocol{
        margin-top: 10px;
    }
    .topcardsnumber{
        font-size: 1.5em;
        font-weight: 500;
        color: #888;
        line-height: 1em;
    }
    .topcardstext{
        color: #aaa;
    }
    .topcardsimg{
        height: 70px; 
        width: auto;
    }
    .communicationsindexcard{
        background-color: hsl(87deg 55% 65%);
        border: none;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .communicationsindextop{
        padding: 20px 26px;
    }
    .communicationsindextitle{
        color: #fff;
        float: left;
    }
    .communicationsindextmdiv{
        float: right;
    }
    .communicationsindextmcircle{
        font-size: 0.65em !important;
        margin-right: 5px;
        margin-bottom: 2px;
        color: #fff;
        border: 1.5px solid #fff;
        border-radius: 50%;
    }
    .communicationsindextm{
        color: hsl(87deg 55% 65%);
        background-color: #fff;
        border-radius: 25px;
        margin: 0 5px;
        padding: 5px 20px;
        font-size: 0.9em;
    }
    .communicationsindextime{
        font-weight: 700;
    }
    .chartPadding{
        padding: 0 2em;
    }
    .chartTabWrapper{
        z-index: 1;
        bottom: 1em;
    }
    .chartTab{
        overflow-x:hidden;
    }
    .chartCard{
        margin-right: 1em;
        min-width: 210px;
        max-width: 210px;
        background-color: rgba(256, 256, 256,  .8);
    }
    .chartCard:last-child{
        margin: 0;
    }    
    .chartArrow{
        padding-right: 0.05em;
        position: absolute;
    } 
    .chartArrow:not(:first-child) {
        padding-left: 0.05em;
        right: 0;
    }
    .chartArrow:hover{
        background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
    .chartcheck{
        margin-bottom: 15px;
    }
    .charttitle{
        color: #888;
        font-weight: 600;
    }
    .chartdesc{
        font-size: 0.8em;
        color: #aaa;
        display: block;
        line-height: 0.75em;
    }
    .charticon{
        float: right;
        font-size: 2.5em;
        font-family: bold;
        color: hsl(87deg 55% 65%);
    }
    .chartbottom{
        background-color: hsl(240deg 100% 99%);
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        height: 70px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
    }
    .bottomcards{
        margin-top: 20px;
    }
    .bottomcards .card:first-child{
        margin-right: 20px;
    }
    .bottomcards .card:last-child{
        margin-left: 20px;
    }
    .bottomcards .card-body{
        padding: 0;
    }
    .bottomcardheader{
        padding: 20px 25px;
        padding-bottom: 50px;
    }
    .bottomcardheader2{
        border-bottom: 0.5px solid #eee;
    }
    .bottomcardicondiv{
        float: right;
        color: #fff;
        background-color: hsl(87deg 46% 59%);
        padding: 4px 20px;
        border-radius: 20px;
        cursor: pointer;
    }
    .bottomcardicondiv:hover{
        background-color: hsl(87deg 40% 50%);
    }
    .bottomcardicon{
        font-size: 1.2em;
    }
    .operators:first-child{
        margin-bottom: 30px;
    }
    .operators{
        margin: 0px 25px;
        padding: 2px 20px;
        border-left: 4px solid;
        padding-bottom: 10px;
    }
    .onlineoperators{
        color: hsl(87deg 41% 57%);
        border-color: hsl(87deg 41% 57%);
    }
    .offlineoperators{
        color: hsl(360deg 96% 71%);
        border-color: hsl(360deg 96% 71%);
    }
    .operatorstitle{
        font-size: 1.3em;
        font-weight: 700;
    }
    .operatorsinfo{
        font-size: 0.8em;
        color: #999;
        line-height: 0.5em;
    }
    .operatorscharttextdiv{
        position: absolute;
        bottom: 50px;
        left: 25px;
    }
    .operatorschart{
        /* margin-top: 95px; */
        position: absolute;
        bottom: 0;
    }
    .operatorschartnumber{
        font-weight: 800;
        font-size: 1.5em;
        color: hsl(209deg 55% 55%);
    }
    .operatorscharttext{
        color: hsl(209deg 55% 55%);
    }
    .bottomcardbody2{
        padding: 0 25px;
    }
    .bottomcardinfo{
        padding: 15px 0;
        border-bottom: 0.5px solid #eee;
    }
    .peopleicon{
        border-radius: 50%;
        height: 47px;
        width: 47px;
    }
    .bottomcardicondiv2{
        font-size: 1.2em;
        background-color: #fff;
        color: #ccc;
        border: 1px solid#ccc;
        padding: 3px 20px;
        padding-top: 0px;
        cursor: pointer;
        margin-top: 4px;
    }
    .bottomcardicondiv2:hover{
        background-color: #ccc;
        color: #fff;
    }
    .disabled{
        cursor: default !important;
        background-color: initial !important;
        color: #eee !important;
        border: 1px solid #eee !important;
    }
    .peoplename{
        color: #888;
        font-weight: 700;
    }
    .peoplejob{
        color: #aaa;
        line-height: 0.7em;
        font-size: 0.9em;
    }
    .peoplenumbertooltip{
        font-size: 2em;
    }
    .lastcallsicon{
        color: #ccc;
        margin-top: 2px;
    }
    .lastcallsicon:hover{
        color: #999;
    }
    .bottomcardsbtn{
        display: flex;
        margin: 30px auto;
        padding: 5px 20px;
        color: #888;
        background-color: #fff;
        border: 0.5px solid #ddd;
        border-radius: 25px;
    }
    .bottomcardsbtn:hover{
        color:#fff;
        background-color: #888;
    }    
    .bottomcardsbtn2{
        margin-top: 12px;
    }
    .numberpopover:hover{
        margin-right: 20px;
    }
    .modal-birthdayheadericon{
        font-size: 1.2em;
        margin-right: 5px;
    }
    .modal-birthdaysubheader{
        color: #999;
        font-size: 0.9em;
        text-align: center;
        border-bottom: 0.5px solid #eee;
        padding: 20px 0;
    }
    .modal-birthdaymain{
        padding: 20px 30px;
    }
    .modal-birthdayitem{
        padding: 5px 0;
    }
    .modal-birthdaymaintitles{
        color: #777;
        font-weight: 500;
        font-size: 0.95em;
        margin-bottom: 10px;
    }
    .modal-birthdaycheck{
        display: inline;
        margin-right: 100px;
        color: #888;
    }
    .modal-birthdayinsertimg{
        border: 0.5px solid #ccc;
        background-color: #eee;
        padding: 80px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 2px;
    }
    .modal-birthdayinsertimgicon{
        font-size: 4em;
        color: #aaa;
    }
    .modal-birthdayinsertimgclick{
        color: hsl(218deg 68% 68%);
        cursor: pointer;
    }
    .modal-birthdayinsertimgtext{
        color: #777;
        font-size: 0.9em;
        font-weight: 500;
    }
    .modal-birthdayinsertimgsubtext{
        color: #999;
        font-size: 0.85em;
    }
    .modal-birthdaybtn{
        background-color: #fff;
        color: #777;
        font-weight: 500;
        border: 0.5px solid #eee;
        margin-right: 10px;
        padding: 8px 30px;
    }
    .modal-birthdaybtn:hover{
        background-color: #777;
        color: #fff;
    }
    .modal-birthdaybtnsend{
        background-color: hsl(142deg 38% 59%);
        color: #fff;
        border: none;
    }
    .modal-birthdaybtnsend:hover{
        background-color: hsl(142deg 30% 50%);
        color: #fff;
    }
    .fbtnstatus{
        padding: 10px;
        color: #fff;
        border-radius: 5px;
        text-align: center;
        position: fixed;
        top: 15vh;
        right: 20px;
        z-index: 3;
    }
    label,
    input[type="checkbox"] + span,
    input[type="checkbox"] + span::before
    {
        display: inline-block;
        vertical-align: middle;
    }
    label *
    {
        cursor: pointer;
    }
    input[type="checkbox"]
    {
        opacity: 0;
        position: absolute;
    }
    input[type="checkbox"] + span
    {
        color: #888;
        font-size: 0.9em;
        display: inline-flex;
        align-items: center;
    }
    label:hover span::before
    {
        -moz-box-shadow: 0 0 2px #ccc;
        -webkit-box-shadow: 0 0 2px #ccc;
        box-shadow: 0 0 2px #ccc;
    }
    input[type="checkbox"] + span::before
    {
        content: "";
        width: 18px;
        height: 18px;
        margin: 0 4px 0 0;
        border: solid 1px #ccc;
        line-height: 14px;
        text-align: center;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
    }
    input[type="checkbox"]:checked + span::before
    {
        color: #666;
        content: "\2713";
        font-size: 12px;
        font-weight: 900;
    }
    input[type="checkbox"]:disabled + span
    {
        cursor: default;
        -moz-opacity: .4;
        -webkit-opacity: .4;
        opacity: .4;
    }
    .toggle-emoji{
        position: absolute;
        bottom: 0;
        right: 8px;
        background-color: transparent;
        border: none;
        color: #aaa;
        box-shadow: none !important;
        cursor: default;
    }
    .toggle-emoji-icon{
        cursor: pointer;
        font-size: 2em;
    }
    .toggle-emoji-icon:hover{
        color: #555;
    }
    .emoji-picker{
        bottom: 40px;
        right: 0px;
    }
    .topMenusWrapper > div:last-child{
        padding: 0 !important;
    }
    @media (max-width: 1023px) {
        .topcardscol:first-child{
            margin-right: 0.5em;
        }
        .topcardscol:last-child{
            margin-left: 0.5em;
        }
        .chartcard:last-child{
            margin-right: 1em;
        }
        .chartcard:first-child{
            margin-left: 1em;
        }
        .charttitle{
            font-size: 0.8em;
            white-space: nowrap;
        }
        .chartdesc{
            line-height: 1em;
        }
        .bottomcardheader{
            padding: 1em;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .bottomcardbody2{
            padding: 0 1em;
        }
        .peopleicon{
            width: 2.5em;
            height: 2.5em;
        }
        .bottomcards,.communicationsindexcard{
            margin-top: 1em;
        }
        .bottomcards .card:first-child{
            margin-right: 1em;
        }
        .bottomcards .card:last-child{
            margin-left: 1em;
        }
    }
    @media (max-width: 768px) {
        .peopleiconcol{
            display: none;
        }
    }
    @media(max-width:425px) {
        .topcardscol{
            margin: 0 !important;
        }
        .topcardscol:first-child{
            margin-bottom: 1em !important;
        }
        .topcardsimg{
            display: none;
        }
        .communicationsindextop{
            padding: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .bottomcards .card{
            margin: 1em 0;
        }
        .bottomcards .card:last-child,.bottomcards .card:first-child{
            margin: 0;
        }
        .operatorscard{
            height: 540px;
        }
        .communicationsindextmdiv,.communicationsindextm{
            display: flex;
            align-items: center;
        }
    }
</style>